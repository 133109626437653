<template>
    <div class ="profile">
      <div v-if="showPaypal" class="clickblock"  style="background-color: rgba(255, 255, 255, 0.9);"> 
        <div  style="position:absolute; top: 5%; width: 100%; text-align: center; vertical-align: middle; text-shadow: 15px;">
          <div class="mt-4 float-end" @click="(showPaypal=false)">
            <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
              <font-awesome-icon class="fa-2xl" icon="fa-solid fa-close"/>
            </button>
          </div>
        <h6>{{buy_item}} - ${{buy_price}}</h6>
        </div>
        <div id="paypal-button-container" style="position:absolute; top: 10%; width: 100%; text-align: center; vertical-align: middle; text-shadow: 15px;"></div>
      </div>
      <div v-if="LoadingThumb" class="clickblock" > 
          <h3 style="position:absolute; top: 50%; width: 100%; text-align: center; vertical-align: middle; text-shadow: 15px;  color: #007bff; ">GENRATING THUMBNAIL</h3>
          <square style="position:absolute; top: 50%; left: 50%;"></square>
      </div>
      <div id="clickblock" class="clickblock" v-if ="show_rpm" v-on:click="closeRPM($event)">
        <iframe  id="frame" class="iframe" allow="camera *; microphone *" src="https://redpillgo.readyplayer.me/avatar?frameApi&clearCache=true" style="border-width: 0px;"></iframe>
      </div>
      <div class="inputbox" v-if = "show_name_input" style="width: 300px; height: 200px;">
          <div class="card-body text-center">
              <br>
              <b-form-input v-model="character_name" placeholder="Give your character a name"></b-form-input>
              <br>
              <!-- <b-button :disabled="character_name==''" style="width: 160px !important; background-color: #007bff; " variant="primary" @click="renderRPMAvatar(character_url)" >Upload</b-button> -->
              <b-button :disabled="character_name==''" style="width: 160px !important; background-color: #007bff; " variant="primary" @click="SetName" >Set</b-button>
          </div>
      </div>
    <div class="py-4 container-fluid">
      <!-- <div
        class="mt-4 page-header min-height-200 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/white-curved.jpeg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-faded-gored opacity-8"><h5 class="mb-1" style="color:white; margin: 30px;">Assets</h5> </span>
        
        
      </div> -->
      <!-- <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
         
            <div class="avatar avatar-xl rounded-circle shadow-sm">
             <h3 class="mb-1" style="color:#d91b5c">D</h3> 
            </div>
           
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{user_email}}</h5>
              <p class="mb-0 text-sm font-weight-bold">Lincense: {{user_date}}</p> 
              <p class="mb-0 text-sm font-weight-bold">Credit: {{user_credit}}</p> 
            </div>
            
          </div>
          <div style=" display: flex; justify-content: flex-end; color: white; font-size: small; cursor: pointer; background-color: red:  !important;;" >
            <a  style="margin-top: -0px; color: gray;" @click="logout" >
              <font-awesome-icon icon="fa fa-user" />
              <span style="padding-left: 10px;  justify-content:end">Sing Out</span>
            </a>
          </div>
          <div
  
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          >
  
          </div>
        </div>
      </div> -->
    </div>
    
      
  
  
      
    <!-- </div> -->
    <!-- <input type="file" ref="file" style="display: none" accept=".vrm" @input="onFile(this)"> -->
    <b-form-file ref="fileinput"  
        v-model="prjFile"
        placeholder="VRM file..."
        drop-placeholder="Drop file here..."
        style="width: 100%;height: 100%; top:25%; text-align:left; display: none;"
        accept=".vrm"
        
    ></b-form-file>
    <my-upload ref="cropimage" field="img"
                          :width="300"
                          :height="300"
                          @crop-success="cropSuccess"
                          @input="modelValue"
                          :params="params"
                          :headers="headers"
                          :no-circle="true"
                          ki="0"
                          lang-type="en"
                          :value.sync="show"
                          img-format="jpg"></my-upload>
    <!-- <b-img hidden :src="imgDataUrl" crossorigin="anonymous" width="0"/> -->
    <!-- <a
      class="btn bg-gradient-dark w-100"
     
      >Create New Character</a
    > -->
    <div class="col-6 text-center" style=" width:100%; margin-bottom: 10px;" >
      
      <soft-button color="gored" variant="gradient" style=" margin-top: 10px;" class="center" @click="showRPMframe">
            Create new character
      </soft-button>
      <i style=" margin: 10px;"></i>
      <soft-button color="dark" variant="gradient" style=" margin-top: 10px;" @click="showFilePicker" >
        Upload VRM Character
      </soft-button>
      
    </div>
    <br v-if="files.length>0">
    <div v-if="files.length>0" class="card mb-4">
      
      <div class="card-header pb-0">
        <h6>Upload New Characters</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Thumbnail
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Name
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Size
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Price
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Action
                  </th>
                  <th class="text-secondary opacity-7"></th>
                </tr>
              </thead>
              <tbody>
                  <tr id="my-table"  :key="index"  v-for="(item, index) in files">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div>
                          <div class="avatar avatar-xl rounded-circle shadow-sm" @click="OnEditThumb(index)">
                            <img :src="`${item.sourceImgUrl}`"/>
                            <!-- <img src="@/assets/img/bruce-mars.jpg"/> -->
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <h6 class="mb-0 text-sm">{{item.name}}</h6>
                    </td>
                    <td>
                      <h6 class="mb-0 text-sm">{{(item.size / 1048576).toFixed(2)}} MB</h6>
                    </td>
                    <td>
                      <h6 class="mb-0 text-sm">{{item.price}}</h6>
                    </td>
                    <td class="align-middle">
                      <a
                        
                        @click="OnUpload(index)"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                        style="cursor: pointer; color:darkgreen !important"
                        >Upload</a
                      >
                      <i style="margin-left:20px;"/>
                      <a
                        @click="OnDelete(index)"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                        style="cursor: pointer; color: #d91b5c !important;"
                        >Delete</a
                      >
                    </td>
                    <td class="align-middle">
                      
                    </td>
                    
                  </tr>
              </tbody> 
            </table> 
          </div>
        </div>
      </div>
    <br>
    <div v-if="items.length>0" class="card mb-4">
      <div class="card-header pb-0">
        <h6>My Characters</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Thumbnail
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Name
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
                <tr id="my-table"  :key="index"  v-for="(item, index) in items">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div>
                        <div class="avatar avatar-xl rounded-circle shadow-sm" @click="OnEditThumb(item)">
                          <img :src="`${item.Image}`"/>
                          <!-- <img src="@/assets/img/bruce-mars.jpg"/> -->
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <h6 class="mb-0 text-sm">{{item.Name}}</h6>
                    <!-- <h6 class="mb-0 text-sm">MY NAME</h6> -->
                  </td>
                  <td class="align-middle">
                    <a
                      v-if="current_item==item"
                      @click="OnUploadThumb(item)"
                      class="text-secondary font-weight-bold text-xs"
                      data-toggle="tooltip"
                      data-original-title="Edit user"
                      style="cursor: pointer; color: darkgreen !important;"
                      >Upload</a
                    >
                  </td>
                  <td class="align-middle">
                    <a
                      @click="OnDeleteCharacter(item.Name)"
                      class="text-secondary font-weight-bold text-xs"
                      data-toggle="tooltip"
                      data-original-title="Edit user"
                      style="cursor: pointer; color: #d91b5c !important;"
                      >Delete</a
                    >
                  </td>
                  
                </tr>
            </tbody> 
          </table> 
        </div>
      </div>
    </div>
  
  </div>
  </template>
  
  <script>
  
  import setTooltip from "@/assets/js/tooltip.js";
  import { Auth } from 'aws-amplify';
  import { API , Storage} from 'aws-amplify';
  import SoftButton from "@/components/SoftButton.vue";
  //mport SoftInput from "@/components/SoftInput.vue";
  import * as aws_helper from "@/amplify_helper.js";
  import myUpload from 'vue-image-crop-upload/upload-2.vue';
  import data2blob from 'vue-image-crop-upload/utils/data2blob.js';
  import { mapMutations } from "vuex";
  import { loadScript } from "@paypal/paypal-js";
  //import DefaultInfoCard from "@/components/DefaultInfoCard.vue";
  //import { geoip2 } from "geoip-js.com/js/apis/geoip2/v2.1/geoip2.js";
  //import { create } from 'vue-modal-dialogs'
  
  export default {
    name: "Assets",
    components: {
       SoftButton,
       //SoftInput,
       //DefaultInfoCard,
       'my-upload': myUpload
    },
    data() {
      return {
        showMenu: false,
        user_email:'',
        user_date:'',
        user_credit:'',
        loading: false,
        items: [],
        features: [],
        params: {
                  token: '123456798',
                  name: 'avatar'
              },
        headers: {
                  smail: '*_~'
              },
        show: false,
        imgDataUrl:'',
        current_item:'',
  
        show_rpm: true,
        show_name_input: false,
        rpm_frame: null,
        custom_url: '',
        character_name: '',
        character_url: '',
        LoadingThumb: false,
        files: [],
        prjFile: null,
        showPaypal: false,
        buy_item: '',
        buy_price: 0,
        storeInventory: [],
        featureInventory: [],
        isTW: true,
        env: 'dev',
        isAdmin:false,
      };
    },
  
    methods: {
      ...mapMutations(["closeAllPanels"]),
      async mountpaypalbutton(id)
      {
        let app = this;
        //prod
        //this.paypal = await loadScript({ "client-id": "AcrU1NHxDpJpdMbqvpaG8hkTrnjDXDoY1BAM5QaFBhENo4RBeBFrIxOVUktXBi1gXPAuIdXWusWAnI0s"});
        //dev
        if(this.env=='dev')
          this.paypal = await loadScript({ "client-id": "AaJgEimNyjVWkcKlUA0FNAEo-IOULm5bWzVy7FZXUIp28VpoC4s9go53hhYH-ezfOcvTZlk9OnFBcKzu"});
        else
          this.paypal = await loadScript({ "client-id": "AcrU1NHxDpJpdMbqvpaG8hkTrnjDXDoY1BAM5QaFBhENo4RBeBFrIxOVUktXBi1gXPAuIdXWusWAnI0s"});
        await this.paypal.Buttons({
          style: {
            shape: 'pill',
            color: 'blue',
            layout: 'vertical',
            label: 'paypal',
            
          },
  
          createOrder: async function() {
            app.loading = true;
            console.log("Paypal createOrder")
            const orderDetails = {    
                headers: { 
                    "Content-Type":"application/json"
                },
                body: {
                  'items': [
                    {
                      'id': id,
                      'quantity': 1,
                    }
                  ],
                }
            }
            try{
              let data = await API.post('rpgPayment','/payment', orderDetails);
              console.log({data});
              app.loading = false;
              return data.orderID;
            }
            catch(err)
            {
              console.log(err.message);
              app.loading = false;
            }
           
          },
  
          onApprove:  async function(data) {
              // Full available details
        
              console.log('Capture result', data, JSON.stringify(data, null, 2));
              const confirmDetails = {    
                headers: { 
                  "Content-Type":"application/json"
                },
                body: {
                  'orderID': data.orderID,
                  'itemID' : id
                }
              }
              try{
                await API.post('rpgPayment','/confirm', confirmDetails);
                // Show a success message within this page, e.g.
                const element = document.getElementById('paypal-button-container');
                element.innerHTML = '';
                element.innerHTML = '<h3>Success! Thank you for your payment!</h3>';
                await app.profile();
                
              }catch(err)
              {
                console.log(err.message)
                const element = document.getElementById('paypal-button-container');
                element.innerHTML = '';
                element.innerHTML = '<h3>Something went wrong!</h3>';
              }
       
              // Or go to another URL:  actions.redirect('thank_you.html');
              
            //});
          },
  
          onError: function(err) {
            console.log(err);
            app.loading = false;
          }
        }).render('#paypal-button-container');
       this.loading = false;
      },
      async logout()
      {
  
        try {
          await Auth.signOut();
          this.$store.state.isLoggedIn = false;
          this.$router.push("/")
        } catch (error) {
          console.log('error signing out: ', error);
        }
      },
      async profile()
      {
          const profile = await API.get('rpgUserProfile','/profile');
          if(profile)
          {
              this.user_email = profile.email;
              if(profile.license!=undefined)
              {
                  let date = new Date(profile.license);
                  this.user_date = date.toDateString();
              }
              else
                  this.user_date = "no valid license"
              if(profile.features!=undefined)
                this.features = profile.features;
              else this.features = [];
              if(profile.credit)
                  this.user_credit = profile.credit;
              else this.user_credit = 0;
          }
      },
      async getItems()
      {
          this.items = [];
          try{
              const data = await API.get('getassets','/items');
              this.loading = false;
              for(let c in data.private_assets)
              {
                  const item = {    
                      Name: data.private_assets[c][2],
                      Image: await aws_helper.getS3File(data.private_assets[c][1],'no-store'),
                      Info: "vrm character"
                  }
                  //for(let i=0; i<20; i++)
                  this.items.push(item);
              }
              this.imgDataUrl = await aws_helper.getS3File(data.missing);
          }catch(err)
          {
              console.log(err);
          }
      },
      async OnPayment(id, item, price)
      {
        
        if(this.isTW)
        {
          this.OnNewebpay(id, item, price);
        }
        else
        {
          this.OnPaypal(id, item, price);
        }
        
      },
      async OnPaypal(id, item, price)
      {
        this.buy_item = item;
        this.buy_price = price;
        this.showPaypal = true;
        try {
              //this.paypal = await loadScript({ "client-id": "AaJgEimNyjVWkcKlUA0FNAEo-IOULm5bWzVy7FZXUIp28VpoC4s9go53hhYH-ezfOcvTZlk9OnFBcKzu"});
              
              console.log("the PayPal JS SDK loaded: ", this.paypal);
          } catch (error) {
              console.error("failed to load the PayPal JS SDK script", error);
          }
        await this.mountpaypalbutton(id);
        //create(DefaultInfoCard);
      },
      async OnBuyCredits()
      {
          this.loading = true;
          let el = this.$refs.coupon_code.getValue();
          //console.log(el);
          let params = {
              'queryStringParameters':
              {
                  code: el
              }
          }
          await API.get('rpgPromocode','/use',params);
          //console.log(data2);
          await this.profile();
          this.$refs.coupon_code.setValue("");
          this.loading = false;
      },
      async  OnDeleteCharacter(name)
      {
  
          this.loading = true;
          const params = {    
                  headers: { 
                      "Content-Type":"application/json"
                  },
                  body: {
                      "key": name
                  }
              }
          let res = await API.del('prgUpload','/items',params);
          console.log(res);
          
          await this.getItems();
    
          this.loading = false;
      },
      OnEditThumb(item)
      {
          
          this.current_item = item;
          this.$refs.cropimage.$refs.fileinput.click();
          //this.show = true;
      },
      configureStorage()
      {
          Storage.configure({
              customPrefix: {
                  private: `private/${this.user_email}/`
              }
          })
      },
      OnDelete(index)
      {  
          this.files.splice(index, 1)
      },
      async OnUpload(index)
      {
        index;
          this.loading = true;
          // let params = {
          //     'queryStringParameters':
          //     {
          //         key: this.files[index].name
          //     }
          // }
          // const data = await API.get('prgUpload','/items',params);
  
          
          let photoBlob;
          
          try{
  
              photoBlob = data2blob(this.files[index].sourceImgUrl,"image/jpeg");
          }
          catch(e)
          {
              let ll = await fetch(this.files[index].sourceImgUrl);
              photoBlob = await ll.blob();
  
          }
          
          const result = await Storage.put(this.files[index].name+"/thumbnail.jpg", photoBlob, {
              level: "private",
              contentType: "image/jpeg",
          });
          console.log(result);
          let vrmBlob = this.files[index];
          let type = this.files[index].mytype;
          console.log({type});
          if(type =="character/vrm")
          {
              await Storage.put(this.files[index].name+"/character.vrm", vrmBlob , {
                  level: "private",
                  contentType: "application/octet-stream",
              });
              const myItem = {    
              headers: { 
                  "Content-Type":"application/json"
              },
              body: {
                  "name": this.files[index].name,
                  "ext": "vrm"
              }
          }
          await API.put('prgUpload','/items', myItem);
          }
          else{
              const response = await fetch(this.character_url);
              const blob = await response.blob();
              await Storage.put(this.character_name+"/character.glb", blob , {
                  level: "private",
                  contentType: "application/octet-stream",
              });
              const myItem = {    
              headers: { 
                  "Content-Type":"application/json"
              },
              body: {
                  "name": this.files[index].name,
                  "ext": "glb"
              }
          }
          await API.put('prgUpload','/items', myItem);
          }
          
          
          // let signedRequest  = await aws_helper.putS3File(this.files[index].name+"/thumbnail.jpg",photoBlob.size)
          // console.log(signedRequest .headers);
          // await fetch(signedRequest .url, {
          //     method: "PUT",
          //     body: photoBlob,
          //     mode: "cors",
          //     referrer: "client",
          //     //headers:{"Content-Length": photoBlob.size.toString()}
          //     headers: signedRequest .headers
          // });
          // //console.log(res);
          // await fetch(data.character, {
          //     method: "PUT",
          //     body: this.files[index],
          //     headers: {
          //         "Content-Length": this.files[index].size
          //     }
          // });
  
          
          this.files.splice(index,1);
          await this.profile();
          await this.getItems();
          
          this.loading = false;
  
      },
      async OnUploadThumb(item)
      {
        
          this.loading = true;
          // let params = {
          //     'queryStringParameters':
          //     {
          //         key: item.Name
          //     }
          // }
          // const data = await API.get('prgUpload','/items',params);
  
          
          let photoBlob;
          
          try{
  
              photoBlob = data2blob(item.Image,"image/jpeg");
          }
          catch(e)
          {
              let ll = await fetch(item.Image);
              photoBlob = await ll.blob();
  
          }
          const result = await Storage.put(item.Name+"/thumbnail.jpg", photoBlob, {
              level: "private",
              contentType: "image/jpeg",
          });
          console.log(result);
  
          await this.profile();
          await this.getItems();
          //this.files.splice(index,1);
          this.loading = false;
  
      },
      cropSuccess(data, field, key) 
      {
          this.loading=false;
          field;key;
          this.show = false;
          if(this.current_item.Image==undefined)
          {
              this.files[this.current_item].sourceImgUrl = data;
              //this.$refs.filetable.refresh();
          }
          else this.current_item.Image = data;
  
          
          },
      modelValue()
      {
          this.show = false;
          this.$refs.cropimage.step=1;
      },
      toggleShow() {
          this.show = !this.show;
          this.$refs.cropimage.step=1;
      },
      closeRPM(event)
      {
          if(event.target.className=="clickblock")
              this.show_rpm = false;
      },
      async showFilePicker()
      {
        if(this.user_credit<20)
        {
          await this.$dialog.alert("You don't have enough credit to create characters.");
          return;
        }
        else{
          this.$refs.fileinput.$el.childNodes[0].click();
        }
      },
      async showRPMframe()
      {
        if(this.user_credit<20)
        {
          await this.$dialog.alert("You don't have enough credit to create characters.");
          return;
        }
        else
          this.show_rpm = true;
      },
      async subscribe(event) {
            
        const json = this.parse(event);
  
        if (json?.source !== 'readyplayerme') {
          return;
        }
  
        //Susbribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName === 'v1.frame.ready') {
          
          document.getElementById("frame").contentWindow.postMessage(
            JSON.stringify({
              target: 'readyplayerme',
              type: 'subscribe',
              eventName: 'v1.**'
            }),
            '*'
          );
        }
  
        // Get avatar GLB URL
        if (json.eventName === 'v1.avatar.exported') {
          
          document.getElementById("frame").hidden = true;
          this.character_url = json.data.url;
          this.renderRPMAvatar(this.character_url)
          this.current_item =-1;
          this.show_name_input = true;
          this.show_rpm = false;
          
        }
      },
  
      parse(event) {
        try {
          return JSON.parse(event.data);
        } catch (error) {
          return null;
        }
      },
      async renderRPMAvatar(url) 
      {
          
          this.LoadingThumb = true;
          const params = 
          {
              model: url,
              scene: "fullbody-portrait-v1",
              armature: "ArmatureTargetMale",
              blendShapes: {
                  "Wolf3D_Head": {
                  "mouthSmile": 0.5
                  }}
          } 
          
  
          var render ="";
          var fileSize = '';
  
          const [thumb_url , size] = await Promise.all([
              new Promise(resolve => {
                  var xhr = new XMLHttpRequest();
                  xhr.open("POST", "https://render.readyplayer.me/render");
                  xhr.setRequestHeader("Content-type", "application/json")
                  xhr.send(JSON.stringify(params))
                  xhr.onload = function(e) {
                      e;
                      
                      resolve(xhr.response);
                  };
                  xhr.onerror = function () {
                      resolve(undefined);
                      console.error("** An error occurred during the XMLHttpRequest");
                  };
                  
              }),
              new Promise(resolve => {
                  var http = new XMLHttpRequest();
                  http.open('HEAD', url, true); // true = Asynchronous
                  http.onreadystatechange = function() {
                      if (this.readyState == this.DONE) {
                          if (this.status === 200) {
                              fileSize = this.getResponseHeader('content-length');
                              console.log('fileSize = ' + fileSize);
      
                          }
                      }
                      resolve(undefined);
                  };
                  http.send();
              })
          ]);
          var jdata = await JSON.parse(thumb_url);
          render = jdata.renders[0];
          size;
          
          fetch(render,
              {mode: 'cors'})
              .then(response => response.blob())
              .then(blob => {
                  console.log(blob);
                  var file_to_upload ={};
                  file_to_upload.sourceImgUrl = render;
                  file_to_upload.mytype = "glb"
                  file_to_upload.price = "20 credits"
                  file_to_upload.item={};
                  file_to_upload.item.size = fileSize;
                  file_to_upload.size = fileSize;
                  file_to_upload.name = this.character_name;
                  this.files.push(file_to_upload);
                  
                  this.current_item = this.files.length-1;
                  if(this.$refs.cropimage)
                    this.$refs.cropimage.setSourceImg(blob);
                  this.show_rpm = false;
                  this.show = true;
                  this.$refs.cropimage.step=2;
                  this.LoadingThumb = false;
          });  
      },
      SetName()
      {
          this.show_name_input = false;
          if(this.current_item>=0)
          {
              this.files[this.current_item].name = this.character_name; 
              //this.$refs.filetable.refresh();
          }
      },
      async OnNewebpay(id, item, price)
      {
        console.log("newebpay "+item+" : "+price);
        const orderDetails = {    
              headers: { 
                  "Content-Type":"application/json"
              },
              body: {
                'items': [
                  {
                    'id': id,
                    'quantity': 1,
                  }
                ],
              }
          }
          this.loading = true;
          let data = await API.post('rpgPayment','/payment',orderDetails);
          console.log({data});
          let form_string ='';
          if(this.env=='dev')
          {
           form_string = `
            <form id="newebpay-container2" method="POST" action="https://ccore.newebpay.com/MPG/mpg_gateway" hidden>
                <input name="MerchantID" value="${data.mid}" readonly>
                <input name="Version" value="2.0" readonly>
                <input name="TradeInfo" value="${data.post_data}" readonly>
                <input name="TradeSha" value="${data.hash}" readonly>
            </form>
            `;
          }
          else
          {
            form_string = `
            <form id="newebpay-container2" method="POST" action="https://core.newebpay.com/MPG/mpg_gateway" hidden>
                <input name="MerchantID" value="${data.mid}" readonly>
                <input name="Version" value="2.0" readonly>
                <input name="TradeInfo" value="${data.post_data}" readonly>
                <input name="TradeSha" value="${data.hash}" readonly>
            </form>
            `;
          }
        document.body.insertAdjacentHTML('beforeend', form_string);
        const form = document.getElementById("newebpay-container2");
  
        form.submit();
        this.loading = false;
      
      }
    },
    async mounted() {
      // let recaptchaScript = document.createElement('script')
      // recaptchaScript.setAttribute('src', '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
      // document.head.appendChild(recaptchaScript)
      // var onSuccess = function(geoipResponse) {
      //   console.log({geoipResponse});
      // };
      // var onError = function(error) {
      //   console.log({error});
      // };
      // geoip2.country(onSuccess, onError);
      console.log("isAdmin:",this.$store.state.isAdmin);
      this.isAdmin = this.$store.state.isAdmin;
      this.closeAllPanels();
      this.$refs.cropimage.$on('src-file-set',(name, type, size) =>{
              name, type, size;
              this.show = true;
          });
      this.$store.state.isAbsolute = true;
      setTooltip(this.$store.state.bootstrap);
      this.rpm_frame = document.getElementById("frame");
      const frame = this.rpm_frame;
      frame.src = `https://redpillgo.readyplayer.me/avatar?frameApi&clearCache=true`;
      window.addEventListener('message',this.subscribe);
      //document.addEventListener('message', this.subscribe);
      this.show_rpm = false;
      this.loading = true;
      await this.profile();
      //this.storeInventory = [];
      // const inventory = await API.get('rpgStoreInventory','/items');
      // this.isTW = inventory.country==="TW";
      // this.env = inventory.env;
      // this.storeInventory = inventory.items;
      // this.featureInventory = [];
      // //inventory.features;
      // for(let f in inventory.features)
      // {
      //   console.log(f);
      //   console.log(this.features.includes('unity'),this.features.includes('vcam'),this.features.includes('unreal'));
      //   console.log(this.features);
      //   if(inventory.features[f].Type=="Unity" && !this.features.includes('unity'))
      //     this.featureInventory.push(inventory.features[f]);
      //   else if(inventory.features[f].Type=="Unreal" && !this.features.includes('unreal'))
      //     this.featureInventory.push(inventory.features[f]);
      //   else if(inventory.features[f].Type=="VCam" && !this.features.includes('vcam'))
      //     this.featureInventory.push(inventory.features[f]);
      // }
      // this.storeInventory.sort(function(a, b) {
      //     var keyA = a.ID, keyB = b.ID;
      //   // Compare the 2 dates
      //   if (keyA < keyB) return -1;
      //   if (keyA > keyB) return 1;
      //   return 0;
      // });
      await this.getItems();
  
      
      this.loading = false;
      this.configureStorage();
      
      
  
    },
    beforeUnmount() {
      
      this.$store.state.isAbsolute = false;
    },
    beforeDestroy (){
      if (this.loading) 
          {
        this.loader.hide();
                  this.loader = null;
      }
      console.log("deactivate()")
    },
    activated: function() {
      console.log("activated()")
    },
    deactivated: function() {
      this.loading = false;
      console.log("deactivate()")
    },
    watch: {
          loading: function (loading) {
              if (loading) 
              {
                  this.loader = this.$loading.show({container: this.$refs.loadingContainer});
              } else 
              {
                  this.loader.hide();
                  this.loader = null;
              }
          },
      prjFile: async function () 
      {
          if(!this.prjFile) return;
          this.files.push(this.prjFile);
          
          this.prjFile.mytype =  this.prjFile.type;
          this.prjFile.sourceImgUrl = this.imgDataUrl;
          let ext = this.prjFile.name.split(".");
          if(ext.length>1 && ext[ext.length-1]=="vrm")
          {
              this.prjFile.mytype = "character/vrm"
          }
          this.prjFile.price = "20 credits"
          this.$refs.fileinput.reset();
          
      }
    },
  };
  </script>
  <style scoped>
  
  .hover:hover{
    transform: scale(1.02);
  }
  .clickblock{
      width:100%;
      height: 100%;
      position: absolute;
      top: 0%;
      left: 0%;
      /* background-color: rgba(94, 94, 94, 0.4); */
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 9;
       position: fixed; 
     overflow-y: scroll;
     top: 0; right: 0; bottom: 0; left: 0;
  }
  .iframe{ 
      width:80%;
      height:80%;
      position: fixed;
      background-color: rgb(94, 94, 94);
      top: 0%;
      left: 0%;
      margin-top: 5%;
      margin-left: 10%;
      z-index: 10;
      box-shadow:0px 0px 8px #2c3e504f;
  }
  .inputbox{ 
      width:300px;
      height:200px;
      position: fixed;
      background-color: rgb(255, 255, 255);
      top: 50%;
      left: 50%;
      margin-top: -200px;
      margin-left: -150px;
      z-index: 11;
      box-shadow:0px 0px 8px #2c3e504f;
  } 
  </style>